import {
  buttonReset,
  buttonSizeStyles,
  buttonVariantStyles,
  commonButtonStyles,
  focusStyles,
  media,
} from "@app/design-system";
import { useResizeObserver } from "@react-aria/utils";
import "mapbox-gl/dist/mapbox-gl.css";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useRef } from "react";
import styled, { css } from "styled-components";
import useUnsafeMapContext from "./useUnsafeMapContext";

const StyledMapView = styled.div`
  position: relative;
  height: 100%;
`;

const controlOverrides = css`
  & :is(.mapboxgl-ctrl-group, .maplibregl-ctrl-group):not(:empty) {
    margin: 0;
    box-shadow: ${(p) => p.theme.boxShadows.md};
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  }

  :is(.mapboxgl-ctrl-scale, .maplibregl-ctrl-scale) {
    margin: 0;
    padding: 0.125rem 0.25rem;
    ${(p) => p.theme.typography.variants.footnoteStrong}
    font-weight: ${(p) => p.theme.fontWeights.semibold};
    color: ${(p) => p.theme.colors.neutrals.textWeak};
    box-shadow: ${(p) => p.theme.boxShadows.md};
    border-color: ${(p) => p.theme.colors.neutrals.text};
  }

  & :is(.mapboxgl-ctrl-group, .maplibregl-ctrl-group) button {
    border: none;
    width: 1.75rem;
    height: 1.75rem;
  }

  & :is(.mapboxgl-ctrl-group, .maplibregl-ctrl-group) button:focus-visible {
    ${focusStyles("ring")}
  }
`;

const bottomRightOverrides = css`
  :is(.mapboxgl-ctrl-bottom-right, .maplibregl-ctrl-bottom-right) {
    display: none;
  }

  @media ${media.lg} {
    :is(.mapboxgl-ctrl-bottom-right, .maplibregl-ctrl-bottom-right) {
      display: flex;
    }
  }

  @media ${media.lg} {
    .mapboxgl-ctrl-zoom-in,
    .mapboxgl-ctrl-zoom-out,
    .mapboxgl-ctrl-compass,
    .maplibregl-ctrl-zoom-in,
    .maplibregl-ctrl-zoom-out,
    .maplibregl-ctrl-compass {
      transform: rotate(90deg);
    }

    .mapboxgl-ctrl-bottom-right,
    .maplibregl-ctrl-bottom-right {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transform: rotate(270deg);
      right: 4.5rem;
      bottom: -2.25rem;
    }
  }
`;

const mapRailBottomRightOverrides = css`
  :is(.mapboxgl-ctrl-bottom-right, .maplibregl-ctrl-bottom-right) {
    bottom: 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 0.75rem;
    padding: 0.5rem;
  }

  @media ${media.lg} {
    :is(.mapboxgl-ctrl-bottom-right, .maplibregl-ctrl-bottom-right) {
      bottom: 0;
    }
  }
`;

const popupOverrides = css`
  &:is(.mapboxgl-popup, .maplibregl-popup).annotations-popup {
    :is(.mapboxgl-popup-tip, .maplibregl-popup-tip) {
      display: none;
    }

    .mapboxgl-popup-content,
    .maplibregl-popup-content {
      background-color: ${(p) => p.theme.colors.neutrals.background};
      padding: 1.5rem;
      box-shadow: ${(p) => p.theme.boxShadows.lg};
      border-radius: ${(p) => p.theme.borderRadiuses.lg}px;

      button {
        ${buttonReset}
        ${commonButtonStyles}
        ${buttonVariantStyles.primary}
        ${buttonSizeStyles.md}
      }
    }
  }
`;

interface StyledMapProps {
  layout?: "default" | "mapRail";
  isRounded: boolean;
}

const StyledMap = styled.div<StyledMapProps>`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  font-family: inherit;
  border-radius: inherit;
  overflow: hidden;

  & :is(.mapboxgl-canvas, .maplibregl-canvas):focus-visible {
    ${focusStyles("inside")}
  }

  ${(p) =>
    p.isRounded &&
    css`
      .mapboxgl-canvas,
      .maplibregl-canvas {
        border-radius: ${p.theme.borderRadiuses.base}px;
      }
    `}

  /* Map-specific style overrides */

  .mapboxgl-ctrl-bottom-left, .maplibregl-ctrl-bottom-left {
    z-index: 0;

    .mapboxgl-ctrl,
    .maplibregl-ctrl {
      margin-bottom: 16px;
      margin-left: 16px;
    }
  }

  .mapboxgl-ctrl-top-left,
  .maplibregl-ctrl-top-left {
    z-index: auto;
    top: 4px;
    left: 4px;

    @media ${media.lg} {
      top: 0;
      left: 0;
    }
  }

  ${controlOverrides}
  ${(p) =>
    p.layout === "mapRail" ? mapRailBottomRightOverrides : bottomRightOverrides}
  ${popupOverrides}
`;

export interface MapViewProps {
  children?: React.ReactNode;
  layout?: "mapRail" | "default";
  mapStyle?: React.CSSProperties;
  isRounded?: boolean;
}

const MapView = ({
  children,
  isRounded = false,
  mapStyle,
  layout = "default",
}: MapViewProps) => {
  const { lib, map, register } = useUnsafeMapContext();

  const ref = useRef<HTMLDivElement>(null);

  const onResize = useCallback(() => {
    map?.resize();
  }, [map]);

  useResizeObserver({ ref, onResize });

  return (
    <StyledMapView ref={ref}>
      <StyledMap
        ref={register}
        isRounded={isRounded}
        layout={layout}
        style={mapStyle}
        data-testid={`${lib}-container`}
      />
      {map && children}
    </StyledMapView>
  );
};

export default MapView;
