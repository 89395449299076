import { Spinner } from "@app/design-system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import type { MapImage } from "../utils/loadImage";

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const StyledText = styled.div`
  ${(p) => p.theme.typography.variants.footnote}
`;

const Styled2ColGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  gap: 8px;
`;

interface PublicSafetyNetworkFeatureServerIconJson {
  layers: readonly {
    layerId: number;
    layerName: string;
    layerType: "Feature Layer" | "Raster Layer";
    minScale: number;
    maxScale: number;
    legend: readonly {
      label: string;
      url: string;
      imageData: string;
      contentType: string;
      height: number;
      width: number;
    }[];
  }[];
}

type SymbologyMap = Record<string, readonly MapImage[]>;

interface LoadSymbologyParams {
  accessToken: string | undefined;
  signal: AbortSignal;
}

const loadSymbologyList = (params: LoadSymbologyParams) => {
  const symbologyUrl = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/AthenaMobileInfrastructure/MapServer/legend?f=pjson`;

  return fetch(symbologyUrl, {
    headers: symbologyUrl.includes("shared-athena")
      ? { Authorization: `Bearer ${params.accessToken}` }
      : undefined,
    signal: params.signal,
  })
    .then((response) => {
      params.signal.throwIfAborted();
      return response.json() as Promise<PublicSafetyNetworkFeatureServerIconJson>;
    })
    .then((data): SymbologyMap => {
      params.signal.throwIfAborted();
      return Object.fromEntries(
        data.layers.map((layer) => [
          layer.layerName,
          layer.legend.map((image) => ({
            src: `data:${image.contentType};base64,${image.imageData}`,
            imageId: image.label,
          })),
        ]),
      );
    })
    .catch(catchAbortError);
};

const PublicSafetyNetworkLegend = () => {
  const accessToken = useAuthAccessToken();
  const [symbology, setSymbology] = useState<SymbologyMap>();

  useEffect(() => {
    const controller = new AbortController();

    loadSymbologyList({ accessToken, signal: controller.signal }).then(
      (loadedSymbology) => loadedSymbology && setSymbology(loadedSymbology),
      catchAbortError,
    );

    return () => controller.abort();
  }, [accessToken]);

  const siteMapImages = symbology?.PsnSites;
  const coverageMapImages = symbology?.PsnCoverage;

  return siteMapImages && coverageMapImages ? (
    <Styled2ColGrid>
      <StyledImage src={siteMapImages[0].src} alt="" aria-hidden />
      <StyledText>Sites</StyledText>

      {coverageMapImages.map((mapImage) => (
        <React.Fragment key={mapImage.imageId}>
          <StyledImage src={mapImage.src} alt="" aria-hidden />
          <StyledText>{mapImage.imageId}</StyledText>
        </React.Fragment>
      ))}
    </Styled2ColGrid>
  ) : (
    <Spinner />
  );
};

export default PublicSafetyNetworkLegend;
